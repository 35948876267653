import React from 'react';
import { RichText } from 'prismic-reactjs';

// styles
import { Container, NormalText } from '@styles';
import { ClassesSection } from './styles';
import { Row, Title } from '../coaches/styles';
import { LeftCol, RightCol } from '../faq/styles';

const Classes = ({ data }) => {
  return (
    <>
      {data.map((reference, i) => (
        <ClassesSection key={i}>
          <Container normal>
            <Row>
              <LeftCol>
                <img
                  src={reference.image && reference.image.url}
                  alt={reference.image && reference.image.alt}
                />
                <RichText
                  render={reference.bottom_text}
                  Component={NormalText}
                />
              </LeftCol>
              <RightCol>
                <Title>{RichText.asText(reference.title)}</Title>
                <RichText render={reference.text} Component={NormalText} />
              </RightCol>
            </Row>
          </Container>
        </ClassesSection>
      ))}
    </>
  );
};

export default Classes;
