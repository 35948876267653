import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import BreadCrumb from '@components/breadcrumb';
import Coaches from '@views/classes';

const RenderBody = ({ doc }) => {
  const classes = doc.classes;

  return (
    <>
      <SEO
        title="Classes"
        uri="classes"
        desc="Come fence with us at our classes!"
      />
      <BreadCrumb name="Classes" />
      <Coaches data={classes} />
    </>
  );
};

export default ({ data }) => {
  const doc = data.prismic.allClassess.edges.slice(0, 1).pop();

  if (!doc) return null;
  return <RenderBody doc={doc.node} />;
};

export const query = graphql`
  {
    prismic {
      allClassess {
        edges {
          node {
            classes {
              image
              title
              text
              bottom_text
            }
          }
        }
      }
    }
  }
`;
